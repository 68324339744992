import {javascriptGenerator, Order} from "blockly/javascript";

// 两个值判断
javascriptGenerator.forBlock['BASE_SYMBOL'] = function (block, generator) {
    const value_1 = generator.valueToCode(block, '1', Order.NONE);
    const dropdown_4 = block.getFieldValue('4');
    const value_3 = generator.valueToCode(block, '3', Order.NONE);

    const code = `${value_1}${dropdown_4}${value_3}`;
    return [code, Order.NONE];
}

// 数字
javascriptGenerator.forBlock['BASE_NUMBER'] = function (block) {
    const number_number = block.getFieldValue('NUMBER');

    const code = `${number_number}`;
    return [code, Order.NONE];
}

// 字符串
javascriptGenerator.forBlock['BASE_STRING'] = function (block, generator) {
    const text_string_name = block.getFieldValue('STRING_NAME');
    let code = `\"${text_string_name}\"`;


    return [code, Order.NONE];
}

// 布尔值
javascriptGenerator.forBlock['BASE_BOOLEAN'] = function (block, generator) {
    const dropdown_boolean = block.getFieldValue('BOOLEAN');

    const code = `${dropdown_boolean}`;
    return [code, Order.NONE];
}

// 转换数字成字符串
javascriptGenerator.forBlock['BASE_TO_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#toString(${value_name})`;
    return [code, Order.NONE];
}

// 拼接字符串
javascriptGenerator.forBlock['BASE_CONCAT_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `#concatString(${value_name},${value_name2})`;
    return [code, Order.NONE];
}

// 逻辑取反
javascriptGenerator.forBlock['BASE_NOT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#not(${value_name})`;
    return [code, Order.NONE];
}



