export const location_block = [
    {
        "type": "LOCATION_CREATE_1",
        "tooltip": "创建坐标",
        "helpUrl": "",
        "message0": "创建坐标 X： %1 Y： %2 Z： %3",
        "args0": [
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_2",
        "tooltip": "创建坐标",
        "helpUrl": "",
        "message0": "创建坐标 X： %1 Y： %2 Z： %3 左右： %4 上下： %5",
        "args0": [
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "yaw",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "pitch",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_3",
        "tooltip": "",
        "helpUrl": "",
        "message0": "创建坐标 世界： %1 X： %2 Y： %3 Z： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "world"
            },
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            }
        ],
        "output": null,
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_3",
        "tooltip": "创建坐标",
        "helpUrl": "",
        "message0": "创建坐标 世界： %1 X： %2 Y： %3 Z： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "world"
            },
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_4",
        "tooltip": "创建坐标",
        "helpUrl": "",
        "message0": "创建坐标 世界： %1 X： %2 Y： %3 Z： %4 左右： %5 上下： %6",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "world"
            },
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "yaw",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "pitch",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    }, {
        "type": "LOCATION_GET_X",
        "tooltip": "获取X轴",
        "helpUrl": "",
        "message0": "获取X轴 坐标： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    }, {
        "type": "LOCATION_GET_Y",
        "tooltip": "获取Y轴",
        "helpUrl": "",
        "message0": "获取Y轴 坐标： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    }, {
        "type": "LOCATION_GET_Z",
        "tooltip": "获取Z轴",
        "helpUrl": "",
        "message0": "获取Z轴 坐标： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    }, {
        "type": "LOCATION_GET_YAW",
        "tooltip": "获取水平视角",
        "helpUrl": "",
        "message0": "获取水平视角 坐标： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    }, {
        "type": "LOCATION_GET_PITCH",
        "tooltip": "获取垂直视角",
        "helpUrl": "",
        "message0": "获取垂直视角 坐标： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    }, {
        "type": "LOCATION_GET_TO",
        "tooltip": "获取移动终点只能是 玩家移动 触发器",
        "helpUrl": "",
        "message0": "获取移动终点 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "location",
        "colour": 195
    }, {
        "type": "LOCATION_GET_FROM",
        "tooltip": "获取移动起点只能是 玩家移动 触发器",
        "helpUrl": "",
        "message0": "获取移动起点 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "location",
        "colour": 195
    }




]