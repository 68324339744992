export const logical_control_block = [
    {
        "type": "LOGICAL_CONTROL_IF",
        "tooltip": "判断控制语句",
        "helpUrl": "",
        "message0": "如果 %1 那么 %2",
        "args0": [
            {
                "type": "input_value",
                "name": "IF0",
                "check": "boolean_block"
            },
            {
                "type": "input_statement",
                "name": "THEN",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165
    }, {
        "type": "LOGICAL_CONTROL_IF_ELSE",
        "tooltip": "",
        "helpUrl": "",
        "message0": "如果 %1 那么 %2 否则 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "IF",
                "check": "boolean_block"
            },
            {
                "type": "input_statement",
                "name": "THEN",
                "check": "flow"
            },
            {
                "type": "input_statement",
                "name": "ELSE",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165
    }, {
        "type": "LOGICAL_CONTROL_REPEAT_DO",
        "tooltip": "重复多少次做一件事情",
        "helpUrl": "",
        "message0": "重复 次数： %1 做什么事情 %2 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "aaa"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    },
    {
        "type": "LOGICAL_CONTROL_WHILE_DO",
        "tooltip": "当满足条件后重复做一件事情",
        "helpUrl": "",
        "message0": "当满足条件 布尔： %1 一直做什么事情 %2 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "boolean_block"
            },
            {
                "type": "input_dummy",
                "name": "22"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    }, {
        "type": "LOGICAL_CONTROL_TIMER_1",
        "tooltip": "延迟多少tick执行 什么事情",
        "helpUrl": "",
        "message0": "一次性定时器 延迟Tick: %1 做什么 %2 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "222"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    }, {
        "type": "LOGICAL_CONTROL_TIMER_2",
        "tooltip": "延迟多少tick执行并且间隔多少tick执行 什么事情",
        "helpUrl": "",
        "message0": "重复性定时器 延迟Tick: %1 间隔多少Tick执行: %2 做什么 %3 %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "222"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    }






]