import {getToken, setToken} from '@/utils/auth'
import {getUserInfoApi, loginApi} from "@/api/user";

const state = {
    token: getToken(),
    name: '',
    avatar: '',
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
}
const actions = {
    qqLogin({commit}, obj) {
        return new Promise((resolve, reject) => {
            loginApi(obj).then(response => {
                commit('SET_TOKEN', response.data.token)
                commit('SET_AVATAR', response.data.img)
                setToken(response.data.token)
                this.dispatch('user/getUserInfo').then()
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    getUserInfo({commit}) {
        return new Promise((resolve, reject) => {
            getUserInfoApi().then(response => {
                const data = response.data
                commit('SET_NAME', data.nickName)
                commit('SET_AVATAR', data.img)
                resolve(data)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
