export const event_block = [
    {
        "type": "EVENT_NOW",
        "tooltip": "获取当前事件",
        "helpUrl": "",
        "message0": "获取当前事件 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "event",
        "colour": 45
    },
    {
        "type": "EVENT_SET_CANCELLED",
        "tooltip": "取消事件",
        "helpUrl": "",
        "message0": "取消事件 事件： %1 布尔： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "event",
                "check": "event"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 45
    }, {
        "type": "EVENT_GET_LOCALE",
        "tooltip": "获取选择的语言，切换语言的时候触发",
        "helpUrl": "",
        "message0": "获取选择的语言，切换语言的时候触发 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "string",
        "colour": 45
    }


]