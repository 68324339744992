import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['LOCATION_CREATE_1'] = function (block, generator) {
    const value_x = generator.valueToCode(block, 'X', Order.NONE);
    const value_y = generator.valueToCode(block, 'Y', Order.NONE);
    const value_z = generator.valueToCode(block, 'Z', Order.NONE);

    const code = `#createLocation(${value_x},${value_y},${value_z})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['LOCATION_CREATE_2'] = function (block, generator) {
    const value_x = generator.valueToCode(block, 'X', Order.NONE);
    const value_y = generator.valueToCode(block, 'Y', Order.NONE);
    const value_z = generator.valueToCode(block, 'Z', Order.NONE);
    const value_yaw = generator.valueToCode(block, 'yaw', Order.NONE);
    const value_pitch = generator.valueToCode(block, 'pitch', Order.NONE);

    const code = `#createLocation(${value_x},${value_y},${value_z},${value_yaw},${value_pitch})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['LOCATION_CREATE_3'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_x = generator.valueToCode(block, 'X', Order.NONE);
    const value_y = generator.valueToCode(block, 'Y', Order.NONE);
    const value_z = generator.valueToCode(block, 'Z', Order.NONE);

    const code = `#createLocation(${value_name},${value_x},${value_y},${value_z})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['LOCATION_CREATE_4'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_x = generator.valueToCode(block, 'X', Order.NONE);
    const value_y = generator.valueToCode(block, 'Y', Order.NONE);
    const value_z = generator.valueToCode(block, 'Z', Order.NONE);
    const value_yaw = generator.valueToCode(block, 'yaw', Order.NONE);
    const value_pitch = generator.valueToCode(block, 'pitch', Order.NONE);

    const code = `#createLocation(${value_name},${value_x},${value_y},${value_z},${value_yaw},${value_pitch})`;
    return [code, Order.NONE];
}


javascriptGenerator.forBlock['LOCATION_GET_X'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getX(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['LOCATION_GET_Y'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getY(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['LOCATION_GET_Z'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getZ(${value_name})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['LOCATION_GET_YAW'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getYaw(${value_name})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['LOCATION_GET_PITCH'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getPitch(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['LOCATION_GET_TO'] = function () {

    const code = `#getTo`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['LOCATION_GET_FROM'] = function () {

    const code = `#getFrom`;
    return [code, Order.NONE];
}