export const player_block = [
    {
        "type": "PLAYER_GET_EVENT",
        "tooltip": "通过事件获取一个玩家实例",
        "helpUrl": "",
        "message0": "获取玩家实例 事件： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "PLAYER",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_HEALTH",
        "tooltip": "获取玩家血量",
        "helpUrl": "",
        "message0": "获取玩家血量  玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": [
            "double",
            "number"
        ],
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_SET_HEALTH",
        "tooltip": "设置一个玩家的血量",
        "helpUrl": "",
        "message0": "设置玩家血量 玩家： %1 血量： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NUMBER",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    },
    {
        "type": "PLAYER_GET_NAME",
        "tooltip": "通过玩家实例获取玩家名字",
        "helpUrl": "",
        "message0": "获取玩家名字 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240,
    },
    {
        "type": "PLAYER_HAS_PERMISSION",
        "tooltip": "",
        "helpUrl": "",
        "message0": "检查是否拥有权限 玩家： %1 权限： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "PERMISSION",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 210,
    },
    {
        "type": "PLAYER_SET_FLY",
        "tooltip": "设置玩家飞行状态",
        "helpUrl": "",
        "message0": "设置飞行 玩家： %1 状态： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "BOOLEAN",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    },
    {
        "type": "PLAYER_SEND_TITLE",
        "tooltip": "给玩家发送一个标题",
        "helpUrl": "",
        "message0": "给 玩家： %1 发送一条标题 %2 主标题： %3 %4 副标题： %5 %6 下方时间以 Tick 为单位，20 Tick 为 1 秒 %7 标题淡入时间： %8 Tick %9 %10 标题停留/展示时长： %11 Tick %12 %13 标题淡出时间： %14 Tick %15",
        "args0": [
            {
                "type": "input_value",
                "name": "1",
                "check": "PLAYER"
            },
            {
                "type": "input_end_row",
                "name": "4"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_end_row",
                "name": "8"
            },
            {
                "type": "input_value",
                "name": "3",
                "check": "string"
            },
            {
                "type": "input_end_row",
                "name": "9"
            },
            {
                "type": "input_end_row",
                "name": "5"
            },
            {
                "type": "field_number",
                "name": "fadeIn",
                "value": 10,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "fadeIn_block"
            },
            {
                "type": "input_end_row",
                "name": "6"
            },
            {
                "type": "field_number",
                "name": "stay",
                "value": 70,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "stay_block"
            },
            {
                "type": "input_end_row",
                "name": "7"
            },
            {
                "type": "field_number",
                "name": "fadeOut",
                "value": 20,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "fadeOut_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": true
    },
    {
        "type": "PLAYER_RESET_TITLE",
        "tooltip": "重置标题",
        "helpUrl": "",
        "message0": "重置标题 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_LOCATION",
        "tooltip": "通过玩家获取坐标",
        "helpUrl": "",
        "message0": "获取玩家世界坐标 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "location",
        "colour": 240,
    },
    {
        "type": "PLAYER_GET_UNIQUE_ID",
        "tooltip": "获取玩家UUID",
        "helpUrl": "",
        "message0": "获取玩家UUID 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240,
    },
    {
        "type": "PLAYER_SET_RESPAWN_LOCATION",
        "tooltip": "设置玩家重生点",
        "helpUrl": "",
        "message0": "设置玩家重生点 玩家： %1 坐标： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_MAX_HEALTH",
        "tooltip": "获取玩家最大血量",
        "helpUrl": "",
        "message0": "获取玩家最大血量 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    },
    {
        "type": "PLAYER_GET_LEVEL",
        "tooltip": "获取玩家等级",
        "helpUrl": "",
        "message0": "获取玩家等级 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    },
    {
        "type": "PLAYER_SET_LEVEL",
        "tooltip": "设置玩家等级",
        "helpUrl": "",
        "message0": "设置玩家等级 玩家： %1 等级: %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "LEVEL",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    },
    {
        "type": "PLAYER_GET_FLY_SPEED",
        "tooltip": "获取玩家飞行速度",
        "helpUrl": "",
        "message0": "获取玩家飞行速度 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    },
    {
        "type": "PLAYER_SET_FLY_SPEED",
        "tooltip": "设置玩家飞行速度",
        "helpUrl": "",
        "message0": "设置玩家飞行速度 玩家： %1 速度： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    },
    {
        "type": "PLAYER_GET_PING",
        "tooltip": "获取玩家的延迟",
        "helpUrl": "",
        "message0": "获取玩家的延迟 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_GET_FOOD_LEVEL",
        "tooltip": "获取玩家的饱食度",
        "helpUrl": "",
        "message0": "获取玩家的饱食度 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_SET_FOOD_LEVEL",
        "tooltip": "设置玩家的饱食度",
        "helpUrl": "",
        "message0": "设置玩家的饱食度 玩家： %1 饱食度： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    }
    ,
    {
        "type": "PLAYER_GET_ALLOW_FLIGHT",
        "tooltip": "获取玩家是否允许飞行",
        "helpUrl": "",
        "message0": "获取玩家是否允许飞行 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_GET_BED_LOCATION",
        "tooltip": "获取玩家床的位置",
        "helpUrl": "",
        "message0": "获取玩家床的位置 玩家： %1 %2 如果玩家床不在，结果是 null %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_end_row",
                "name": "2"
            }
        ],
        "output": [
            "location",
            "null"
        ],
        "colour": 240
    },
    {
        "type": "PLAYER_GET_ATTACK_COOLDOWN",
        "tooltip": "获取玩家攻击冷却 (游戏版本1.9+)",
        "helpUrl": "",
        "message0": "获取玩家攻击冷却 (游戏版本1.9+) 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    },
    {
        "type": "PLAYER_CHECK_IS_OP",
        "tooltip": "检查是否是OP",
        "helpUrl": "",
        "message0": "检查是否是OP 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 210,
    },
    {
        "type": "PLAYER_TELEPORT",
        "tooltip": "将玩家传送至一个坐标",
        "helpUrl": "",
        "message0": "将玩家传送至一个坐标 玩家： %1 坐标： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "player",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "location",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_MAX_HEALTH",
        "tooltip": "设置最大血量",
        "helpUrl": "",
        "message0": "设置最大血量 玩家： %1 血量： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_LIST_NAME",
        "tooltip": "获取Tab列表中名字",
        "helpUrl": "",
        "message0": "获取Tab列表中名字 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_LIST_NAME",
        "tooltip": "设置Tab列表中名字",
        "helpUrl": "",
        "message0": "设置Tab列表中名字 玩家： %1 名字： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_WEATHER",
        "tooltip": "设置玩家自己的天气，可以和当前世界天气不同",
        "helpUrl": "",
        "message0": "设置天气 玩家： %1 天气： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "align": "RIGHT",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_RESET_WEATHER",
        "tooltip": "重置玩家自己的天气，和当前世界天气保持一致",
        "helpUrl": "",
        "message0": "重置天气 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_TIME",
        "tooltip": "1秒20Tick",
        "helpUrl": "",
        "message0": "设置时间 玩家： %1 Tick： %2 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "TIME",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "TIME",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SET_TIME2",
        "tooltip": "假如相对时间为真则玩家时间会随着世界时间变动而变动,并且保持一个差值,反之客户端时间固定不动. 注意!如果相对时间为真,那么time表示的就是客户端的时间与世界的时间之差(相对于世界的时间),而如果为假那么time表示的就是一个绝对的时间.",
        "helpUrl": "",
        "message0": "设置时间 玩家： %1 Tick： %2 %3 是否相对时间： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "TIME",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "TIME",
                "align": "RIGHT"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_RESET_TIME",
        "tooltip": "将玩家时间变为当前世界时间并与世界时间保持同步",
        "helpUrl": "",
        "message0": "重置时间 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_WALK_SPEED",
        "tooltip": "",
        "helpUrl": "",
        "message0": "获取行走速度 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_WALK_SPEED",
        "tooltip": "速度在-1~1区间",
        "helpUrl": "",
        "message0": "设置行走速度 玩家： %1 速度： %2 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "NAME",
                "value": 1,
                "min": -1,
                "max": 1
            },
            {
                "type": "input_dummy",
                "name": "SPEED",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SET_CAN_PICKUP_ITEMS",
        "tooltip": "设置是否允许捡物品",
        "helpUrl": "",
        "message0": "设置是否允许捡物品 玩家： %1 是否允许： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_CLOSE_INVENTORY",
        "tooltip": "关闭背包窗口",
        "helpUrl": "",
        "message0": "关闭背包窗口 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SPAWN_PARTICLE",
        "tooltip": "生成粒子",
        "helpUrl": "",
        "message0": "生成粒子 玩家： %1 粒子效果 %2 X： %3 Y： %4 Z： %5 数量： %6",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "string",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "X",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SPAWN_PARTICLE_LOCATION",
        "tooltip": "生成粒子",
        "helpUrl": "",
        "message0": "生成粒子 玩家： %1 粒子效果： %2 坐标： %3 数量： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "location",
                "align": "RIGHT",
                "check": "location"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_HEADER",
        "tooltip": "",
        "helpUrl": "",
        "message0": "设置TAB列表上方内容 玩家： %1 内容： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_FOOTER",
        "tooltip": "设置TAB列表下方内容",
        "helpUrl": "",
        "message0": "设置TAB列表下方内容 玩家： %1 内容： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_HEADER_FOOTER",
        "tooltip": "设置TAB列表上方和下方内容",
        "helpUrl": "",
        "message0": "设置TAB列表上方和下方内容 玩家： %1 上方内容： %2 下方内容： %3",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "UP",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "DOWN",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_KICK",
        "tooltip": "踢出玩家",
        "helpUrl": "",
        "message0": "踢出 玩家： %1 原因： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SUDO_CHAT",
        "tooltip": "强制玩家发送一个聊天消息,或强制使用命令(需要在内容前加 \"/\")",
        "helpUrl": "",
        "message0": "强制某个玩家发送一个消息或命令 玩家： %1 内容： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_ABSORPTION_AMOUNT",
        "tooltip": "",
        "helpUrl": "",
        "message0": "设置吸收伤害 玩家： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_INVISIBLE",
        "tooltip": "",
        "helpUrl": "",
        "message0": "设置可见性 玩家： %1 是否可见： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_IS_INVISIBLE",
        "tooltip": "检查是否可见",
        "helpUrl": "",
        "message0": "检查是否可见 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 210
    }, {
        "type": "PLAYER_IS_IN_WATER",
        "tooltip": "检查玩家是否在水中",
        "helpUrl": "",
        "message0": "检查是否在水中 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 210
    }, {
        "type": "PLAYER_SEND_SOUND",
        "tooltip": "播放声音",
        "helpUrl": "",
        "message0": "播放声音 玩家： %1 声音： %2 声音大小： %3 音调： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "SOUND",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "size",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_GAME_MODE",
        "tooltip": "获取玩家游戏模式",
        "helpUrl": "",
        "message0": "获取玩家游戏模式 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_GAME_MODE",
        "tooltip": "设置玩家游戏模式",
        "helpUrl": "",
        "message0": "设置玩家游戏模式 玩家： %1 游戏模式： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_POTION_EFFECT",
        "tooltip": "",
        "helpUrl": "",
        "message0": "设置玩家添加药水效果 玩家： %1 药水效果： %2 持续时间： %3 等级： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "POTION",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "time",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "level",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 225
    }, {
        "type": "PLAYER_SET_GLOWING",
        "tooltip": "是否高亮",
        "helpUrl": "",
        "message0": "设置高亮玩家 玩家： %1 布尔： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "boolean",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 225
    }



]