export const logical_control_contents = [
    {
        "kind": "block",
        "type": "LOGICAL_CONTROL_IF",
        "name": "判断如果"
    }, {
        "kind": "block",
        "type": "LOGICAL_CONTROL_IF_ELSE",
        "name": "判断如果那么否则"
    }, {
        "kind": "block",
        "type": "LOGICAL_CONTROL_REPEAT_DO",
        "name": "重复多少次做一件事情"
    }, {
        "kind": "block",
        "type": "LOGICAL_CONTROL_WHILE_DO",
        "name": "当满足条件后重复做一件事情"
    }, {
        "kind": "block",
        "type": "LOGICAL_CONTROL_TIMER_1",
        "name": "一次性定时器延迟多少tick执行什么事情"
    }, {
        "kind": "block",
        "type": "LOGICAL_CONTROL_TIMER_2",
        "name": "重复性定时器延迟多少tick执行并且间隔多少tick执行什么事情"
    },
]
