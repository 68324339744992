import {javascriptGenerator, Order} from "blockly/javascript";

// 获取玩家世界
javascriptGenerator.forBlock['PLAYER_GET_WORLD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getWorld(${value_name})`;
    return [code, Order.NONE];
}

// 获取世界 通过世界名
javascriptGenerator.forBlock['WORLD_GET_WORLD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getWorld(${value_name})`;
    return [code, Order.NONE];
}

// 生成经验球
javascriptGenerator.forBlock['WORLD_SPAWN_EXPERIENCE'] = function (block, generator) {
    const value_location = generator.valueToCode(block, 'location', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#spawnExperience(${value_location},${value_name});`;
    return code;
}