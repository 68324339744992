import {field_contents} from "@/data/customize/category/contents/field_contents";

export const field_category = [
    {
        "kind": "category",
        "name": "变量组件",
        "colour": "#A55B80",
        "contents": [
            ...field_contents
        ]
    }
]