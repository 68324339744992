export const location_contents = [
    {
        "kind": "block",
        "type": "LOCATION_CREATE_1",
        "name": "创建坐标"
    }, {
        "kind": "block",
        "type": "LOCATION_CREATE_2",
        "name": "创建坐标"
    }, {
        "kind": "block",
        "type": "LOCATION_CREATE_3",
        "name": "创建坐标"
    }, {
        "kind": "block",
        "type": "LOCATION_CREATE_4",
        "name": "创建坐标"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_X",
        "name": "获取X轴"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_Y",
        "name": "获取Y轴"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_Z",
        "name": "获取Z轴"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_YAW",
        "name": "获取水平视角"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_PITCH",
        "name": "获取垂直视角"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_TO",
        "name": "获取移动终点"
    }, {
        "kind": "block",
        "type": "LOCATION_GET_FROM",
        "name": "获取移动起点"
    },

]
