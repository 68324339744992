export const server_block = [
    {
        "type": "SERVER_SET_MOTD",
        "tooltip": "设置服务器MOTD 只能是 服务器列表 事件",
        "helpUrl": "",
        "message0": "设置服务器MOTD 事件： %1 第一行 %2 第二行 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            },
            {
                "type": "input_value",
                "name": "line1",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "line2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 315
    }

]