import {javascriptGenerator, Order} from "blockly/javascript";

// 发送消息
javascriptGenerator.forBlock['MESSAGE_PLAYER_SEND_MESSAGE'] = function (block, generator) {
    let value_1 = generator.valueToCode(block, '1', Order.NONE);
    let value_2 = generator.valueToCode(block, '2', Order.NONE);


    const code = `#sendMessage(${value_1},${value_2});`
    return code;
}

// 发送给全体消息
javascriptGenerator.forBlock['MESSAGE_PLAYER_SEND_MESSAGE_ALL'] = function (block, generator) {
    const value_2 = generator.valueToCode(block, '2', Order.NONE);


    const code = `#sendMessage(${value_2});`;
    return code;
}

// 设置加入消息
javascriptGenerator.forBlock['MESSAGE_SET_JOIN_MESSAGE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setJoinMessage(αEVENT@EVENT,${value_name});`;
    return code;
}

// 设置退出消息
javascriptGenerator.forBlock['MESSAGE_SET_QUIT_MESSAGE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setQuitMessage(αEVENT@EVENT,${value_name});`;
    return code;
}

// 设置聊天格式
javascriptGenerator.forBlock['MESSAGE_SET_CHAT_FORMAT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setChatFormat(αEVENT@EVENT,${value_name});`;
    return code;
}

javascriptGenerator.forBlock['MESSAGE_GET_CHAT_MESSAGE'] = function () {

    const code = '#getChatMessage(αEVENT@EVENT)';
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['MESSAGE_GET_COMMAND'] = function () {

    const code = '#getCommand(αEVENT@EVENT)';
    return [code, Order.NONE];
}