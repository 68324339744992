import service from "@/utils/request";

export function getUserInfoApi() {
    return service({
        url: '/function/api/v1/user/getInfo',
        method: 'get'
    })
}

export function loginApi(data) {
    return service({
        url: '/function/api/v1/user/login/qq',
        method: 'post',
        data
    })
}
