export const field_contents = [
    {
        "kind": "block",
        "type": "FIELD_CREATE_PLAYER",
        "name": "创建玩家变量"
    },
    {
        "kind": "block",
        "type": "FIELD_GET_PLAYER",
        "name": "获取玩家变量"
    }, {
        "kind": "block",
        "type": "FIELD_CREATE_STRING",
        "name": "创建字符串变量"
    },
    {
        "kind": "block",
        "type": "FIELD_GET_STRING",
        "name": "获取字符串变量"
    }, {
        "kind": "block",
        "type": "FIELD_CREATE_NUMBER",
        "name": "创建数字变量"
    }, {
        "kind": "block",
        "type": "FIELD_GET_NUMBER",
        "name": "获取数字变量"
    }, {
        "kind": "block",
        "type": "FIELD_CREATE_BOOLEAN",
        "name": "创建布尔变量"
    }, {
        "kind": "block",
        "type": "FIELD_GET_BOOLEAN",
        "name": "获取布尔变量"
    }, {
        "kind": "block",
        "type": "FIELD_CREATE_LOCATION",
        "name": "创建坐标变量"
    }, {
        "kind": "block",
        "type": "FIELD_GET_LOCATION",
        "name": "获取坐标变量"
    }, {
        "kind": "block",
        "type": "FIELD_CREATE_WORLD",
        "name": "创建世界变量"
    }, {
        "kind": "block",
        "type": "FIELD_GET_WORLD",
        "name": "获取世界变量"
    },

]