import {logical_control_category} from "@/data/customize/category/logical_control_category";
import {field_category} from "@/data/customize/category/field_category";
import {player_category} from "@/data/customize/category/player_category";
import {base_category} from "@/data/customize/category/base_category";
import {message_category} from "@/data/customize/category/message_category";
import {location_category} from "@/data/customize/category/location_category";
import {world_category} from "@/data/customize/category/world_category";
import {event_category} from "@/data/customize/category/event_category";
import {server_category} from "@/data/customize/category/server_category";

export const index_category = [
    ...event_category,
    ...field_category,
    ...player_category,
    ...message_category,
    ...location_category,
    ...world_category,
    ...server_category,
    ...logical_control_category,
    ...base_category

]