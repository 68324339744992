import {event_contents} from "@/data/customize/category/contents/event_contents";

export const event_category = [
    {
        "kind": "category",
        "name": "事件组件",
        "colour": "#A5935B",
        "contents": [
            ...event_contents
        ]
    }
]