import service from "@/utils/request";

export function getUserTriggerListApi(params) {
    return service({
        url: '/function/api/v1/file/getUserTriggerList',
        method: 'get',
        params
    })
}

export function createTriggerApi(data) {
    return service({
        url: '/function/api/v1/file/createTrigger',
        method: 'post',
        data
    })
}

export function getTriggerComponentListApi(params) {
    return service({
        url: '/function/api/v1/file/getTriggerComponentList',
        method: 'get',
        params
    })
}

export function saveAndUpdateTriggerApi(data) {
    return service({
        url: '/function/api/v1/file/saveAndUpdateTrigger',
        method: 'post',
        data
    })
}

export function deleteTriggerApi(params) {
    return service({
        url: '/function/api/v1/file/deleteTrigger',
        method: 'get',
        params
    })
}