import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['LOGICAL_CONTROL_IF'] = function (block, generator) {
    const value_if0 = generator.valueToCode(block, 'IF0', Order.NONE);
    const statement_then = generator.statementToCode(block, 'THEN');

    return `#if(${value_if0})#then {${statement_then}}`;
}

javascriptGenerator.forBlock['LOGICAL_CONTROL_IF_ELSE'] = function (block, generator) {
    const value_if = generator.valueToCode(block, 'IF', Order.NONE);
    const statement_then = generator.statementToCode(block, 'THEN');
    const statement_else = generator.statementToCode(block, 'ELSE');

    return `#if(${value_if})#then {${statement_then}}#else{${statement_else}}`;
}

javascriptGenerator.forBlock['LOGICAL_CONTROL_REPEAT_DO'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const statement_do = generator.statementToCode(block, 'DO');

    return `#repeat(${value_name})#do{${statement_do}}`;
}

javascriptGenerator.forBlock['LOGICAL_CONTROL_WHILE_DO'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const statement_do = generator.statementToCode(block, 'DO');

    return `#while(${value_name})#do{${statement_do}}`;
}

javascriptGenerator.forBlock['LOGICAL_CONTROL_TIMER_1'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const statement_do = generator.statementToCode(block, 'DO');

    return `#timer(${value_name}){${statement_do}}`;
}

javascriptGenerator.forBlock['LOGICAL_CONTROL_TIMER_2'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);
    const statement_do = generator.statementToCode(block, 'DO');

    return `#timer(${value_name},${value_name2}){${statement_do}}`;
}