// gzipUtil.js
import pako from 'pako';

const gzipUtil = {
    /**
     * 压缩 JSON 数据为 Gzip 格式并返回 Base64 编码的字符串
     * @param {Object} json - 要压缩的 JSON 数据
     * @returns {string} Base64 编码的压缩数据
     */
    compressToBase64: function (json) {
        try {
            // 将 JSON 对象转换为字符串
            const jsonString = JSON.stringify(json);
            // 将字符串转换为 Uint8Array
            const uint8Array = new TextEncoder().encode(jsonString);
            // 使用 pako 进行压缩
            const compressed = pako.gzip(uint8Array);
            // 将压缩后的数据转换为 Base64
            const binaryString = Array.from(compressed).map(byte => String.fromCharCode(byte)).join('');
            return btoa(binaryString);
        } catch (error) {
            console.error('压缩失败:', error);
            throw error;
        }
    },
    compressNoJsonToBase64: function (json) {
        try {
            // 将 JSON 对象转换为字符串
            const jsonString = json;
            // 将字符串转换为 Uint8Array
            const uint8Array = new TextEncoder().encode(jsonString);
            // 使用 pako 进行压缩
            const compressed = pako.gzip(uint8Array);
            // 将压缩后的数据转换为 Base64
            const binaryString = Array.from(compressed).map(byte => String.fromCharCode(byte)).join('');
            return btoa(binaryString);
        } catch (error) {
            console.error('压缩失败:', error);
            throw error;
        }
    },

    /**
     * 解压缩 Gzip 格式的 Base64 数据为 JSON 对象
     * @param {string} base64 - Base64 编码的压缩数据
     * @returns {Object} 解压缩后的 JSON 对象
     */
    decompressFromBase64: function (base64) {
        try {
            // 将 Base64 字符串转换为二进制字符串
            const binaryString = atob(base64);
            // 将二进制字符串转换为 Uint8Array
            const compressedData = new Uint8Array(Array.from(binaryString, char => char.charCodeAt(0)));
            // 使用 pako 解压缩数据
            const decompressed = pako.ungzip(compressedData, {to: 'string'});
            // 将解压缩的字符串转换为 JSON 对象
            return JSON.parse(decompressed);
        } catch (error) {
            console.error('解压缩失败:', error);
            throw error;
        }
    }
};

export default gzipUtil;
