export const world_block = [
    {
        "type": "PLAYER_GET_WORLD",
        "tooltip": "获取玩家所在的世界",
        "helpUrl": "",
        "message0": "获取玩家所在的世界 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "world",
        "colour": 180,
    }, {
        "type": "WORLD_GET_WORLD",
        "tooltip": "获取世界实例",
        "helpUrl": "",
        "message0": "获取世界实例 世界名： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "world",
        "colour": 180
    }, {
        "type": "WORLD_SPAWN_EXPERIENCE",
        "tooltip": "生成经验球 需要大于等于1，否则不会生成经验",
        "helpUrl": "",
        "message0": "生成经验球 坐标： %1 经验数： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "location",
                "check": "location"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 180
    },


]