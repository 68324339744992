export const base_contents = [
    {
        "kind": "block",
        "type": "BASE_SYMBOL",
        "name": "基本块判断"
    },
    {
        "kind": "block",
        "type": "BASE_NUMBER",
        "name": "基本块数字"
    },
    {
        "kind": "block",
        "type": "BASE_STRING",
        "name": "基本块字符串"
    },
    {
        "kind": "block",
        "type": "BASE_BOOLEAN",
        "name": "基本块布尔"
    },
    {
        "kind": "block",
        "type": "BASE_TO_STRING",
        "name": "转换成字符串"
    }, {
        "kind": "block",
        "type": "BASE_CONCAT_STRING",
        "name": "字符串拼接"
    }, {
        "kind": "block",
        "type": "BASE_NOT",
        "name": "取反"
    },
]
