export const base_block = [
    {
        "type": "BASE_SYMBOL",
        "tooltip": "数量两个值输出布尔值",
        "helpUrl": "",
        "message0": "值: %1 %2 %3 值: %4",
        "args0": [
            {
                "type": "input_value",
                "name": "1"
            },
            {
                "type": "field_dropdown",
                "name": "4",
                "options": [
                    [
                        "=",
                        "=="
                    ],
                    [
                        "<",
                        "<"
                    ],
                    [
                        ">",
                        ">"
                    ],
                    [
                        "<=",
                        "<="
                    ],
                    [
                        ">=",
                        ">="
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "2"
            },
            {
                "type": "input_value",
                "name": "3"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    },
    {
        "type": "BASE_NUMBER",
        "tooltip": "创建一个数字",
        "helpUrl": "",
        "message0": "数字： %1 %2",
        "args0": [
            {
                "type": "field_number",
                "name": "NUMBER",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "number",
        "colour": 15
    },
    {
        "type": "BASE_STRING",
        "tooltip": "创建一个文本",
        "helpUrl": "",
        "message0": "文本： %1",
        "args0": [
            {
                "type": "field_input",
                "name": "STRING_NAME",
                "text": ""
            }
        ],
        "output": "string",
        "colour": 15
    }
    ,
    {
        "type": "BASE_TO_STRING",
        "tooltip": "将一个数字转换为字符串",
        "helpUrl": "",
        "message0": "转换字符串 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_BOOLEAN",
        "tooltip": "创建一个布尔类型",
        "helpUrl": "",
        "message0": "布尔： %1 %2",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "BOOLEAN",
                "options": [
                    [
                        "是",
                        "true"
                    ],
                    [
                        "否",
                        "false"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_CONCAT_STRING",
        "tooltip": "字符串拼接",
        "helpUrl": "",
        "message0": "字符串拼接 字符串1： %1 字符串2： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_NOT",
        "tooltip": "将一个布尔值取反",
        "helpUrl": "",
        "message0": "逻辑取反 布尔： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "boolean_block"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }



]
