import axios from 'axios'
import {Message} from 'element-ui'
import {getToken} from '@/utils/auth'
import router from "@/router";
import pages from "@/pages/pages/index.vue";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 50000 // request timeout
})
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Token'] = getToken()
            if (config.method === 'post' && config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        let contentType = response.headers.get("content-type")
        if (contentType === "application/octet-stream;charset=utf-8") {
            return response
        }
        if (!res.success) {
            return Promise.reject(res)
        } else {
            return res;
        }
    },
    error => {
        Message.closeAll();
        Message({
            message: error.response.data.message,
            type: 'error',
            duration: 5 * 1000,
            offset: 60
        })
        //页面跳转
        if (error.response.data.message === "Token失效") {
            Message({
                message: "登录失效，请重新登录",
                type: 'error',
                duration: 5 * 1000,
                offset: 60
            })
           // window.location.href = "/";
            return ;
        }

        return Promise.reject(error)
    }
)

export default service
