import {javascriptGenerator} from "blockly/javascript";


javascriptGenerator.forBlock['Function_block'] = function (block, generator) {
    let text_function_name = block.getFieldValue('FUNCTION_NAME');
    let text_function_description = block.getFieldValue('FUNCTION_DESCRIPTION');
    let checkbox_enable = block.getFieldValue('ENABLE');
    let statement_function = generator.statementToCode(block, 'FUNCTION');
    checkbox_enable = checkbox_enable.toLowerCase()
    return `{"name":"${text_function_name}","description":"${text_function_description}","enable":${checkbox_enable},"expression":"${statement_function}"}`;
}

javascriptGenerator.forBlock['TRIGGER_BLOCK'] = function (block, generator) {
    const statement_4 = generator.statementToCode(block, '4');

    const code = `${statement_4}`;
    return code;
}