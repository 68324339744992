import {javascriptGenerator, Order} from "blockly/javascript";

// 获取玩家实体
javascriptGenerator.forBlock['PLAYER_GET_EVENT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getPlayer(${value_name})`, Order.NONE];
}

// 获取玩家血量
javascriptGenerator.forBlock['PLAYER_GET_HEALTH'] = function (block) {
    // 从块中提取代码
    const valueName = javascriptGenerator.valueToCode(block, 'NAME', Order.NONE);

    // 生成代码并返回元组
    const code = `#getHealth(${valueName})`;
    return [code, Order.NONE]; // 使用 FUNCTION_CALL 优先级，适用于函数调用
};


// 设置玩家血量
javascriptGenerator.forBlock['PLAYER_SET_HEALTH'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_number = generator.valueToCode(block, 'NUMBER', Order.NONE);

    const code = `#setHealth(${value_player},${value_number});`;
    return code;
}

// 获取玩家名字
javascriptGenerator.forBlock['PLAYER_GET_NAME'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getName(${value_name})`;
    return [code, Order.NONE];
}

// 玩家是否有权限
javascriptGenerator.forBlock['PLAYER_HAS_PERMISSION'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_permission = generator.valueToCode(block, 'PERMISSION', Order.NONE);

    const code = `#hasPermission(${value_player},${value_permission})`;
    return [code, Order.NONE];
}

// 设置玩家飞行
javascriptGenerator.forBlock['PLAYER_SET_FLY'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_boolean = generator.valueToCode(block, 'BOOLEAN', Order.NONE);

    const code = `#setFly(${value_player},${value_boolean});`;
    return code;
}

// 发送标题
javascriptGenerator.forBlock['PLAYER_SEND_TITLE'] = function (block, generator) {
    const value_1 = generator.valueToCode(block, '1', Order.NONE);
    const value_2 = generator.valueToCode(block, '2', Order.NONE);
    const value_3 = generator.valueToCode(block, '3', Order.NONE);
    const number_fadein = block.getFieldValue('fadeIn');
    const number_stay = block.getFieldValue('stay');
    const number_fadeout = block.getFieldValue('fadeOut');


    const code = `#sendTitle(${value_1},${value_2},${value_3},${number_fadein},${number_stay},${number_fadeout});`;
    return code;
}

javascriptGenerator.forBlock['PLAYER_RESET_TITLE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#resetTitle(${value_name})；`;
    return code;
}

// 获取玩家坐标
javascriptGenerator.forBlock['PLAYER_GET_LOCATION'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);


    const code = `#getLocation(${value_name})`;
    return [code, Order.NONE];
}

// 获取玩家唯一ID
javascriptGenerator.forBlock['PLAYER_GET_UNIQUE_ID'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getUniqueId(${value_name})`;
    return [code, Order.NONE];
}

// 设置玩家重生点
javascriptGenerator.forBlock['PLAYER_SET_RESPAWN_LOCATION'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setRespawnLocation(${value_player},${value_name});`;
    return code;
}

// 获取玩家最大血量
javascriptGenerator.forBlock['PLAYER_GET_MAX_HEALTH'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getMaxHealth(${value_name})`;
    return [code, Order.NONE];
}

// 获取玩家等级
javascriptGenerator.forBlock['PLAYER_GET_LEVEL'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getLevel(${value_name})`;
    return [code, Order.NONE];
}

// 设置玩家等级
javascriptGenerator.forBlock['PLAYER_SET_LEVEL'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_level = generator.valueToCode(block, 'LEVEL', Order.NONE);

    const code = `#setLevel(${value_player},${value_level});`;
    return code;
}

// 获取玩家飞行速度
javascriptGenerator.forBlock['PLAYER_GET_FLY_SPEED'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getFlySpeed(${value_name})`;
    return [code, Order.NONE];
}

// 设置玩家飞行速度
javascriptGenerator.forBlock['PLAYER_SET_FLY_SPEED'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setFlySpeed(${value_player},${value_name});`;
    return code;
}


// 获取玩家延迟
javascriptGenerator.forBlock['PLAYER_GET_PING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getPing(${value_name})`;
    return [code, Order.NONE];
}

// 获取玩家饥饿度
javascriptGenerator.forBlock['PLAYER_GET_FOOD_LEVEL'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getFoodLevel(${value_name})`;
    return [code, Order.NONE];
}

// 设置玩家饥饿度
javascriptGenerator.forBlock['PLAYER_SET_FOOD_LEVEL'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setFoodLevel(${value_player},${value_name});`;
    return code;
}

// 获取玩家是否允许飞行
javascriptGenerator.forBlock['PLAYER_GET_ALLOW_FLIGHT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getAllowFlight(${value_name})`;
    return [code, Order.NONE];
}

// 获取玩家床位置
javascriptGenerator.forBlock['PLAYER_GET_BED_LOCATION'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getBedLocation(${value_name})`;
    return [code, Order.NONE];
}

// 获取玩家攻击冷却
javascriptGenerator.forBlock['PLAYER_GET_ATTACK_COOLDOWN'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getAttackCooldown(${value_name})`;
    return [code, Order.NONE];
}

// 检查玩家是否为OP
javascriptGenerator.forBlock['PLAYER_CHECK_IS_OP'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#isOp(${value_name})`;
    return [code, Order.NONE];
}

// 将玩家传送至一个坐标
javascriptGenerator.forBlock['PLAYER_TELEPORT'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'player', Order.NONE);
    const value_location = generator.valueToCode(block, 'location', Order.NONE);

    const code = `#teleport(${value_player},${value_location});`;
    return code;
}

// 设置玩家最大血量
javascriptGenerator.forBlock['PLAYER_SET_MAX_HEALTH'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setMaxHealth(${value_player},${value_name});`;
    return code;
}

// 获取玩家列表名
javascriptGenerator.forBlock['PLAYER_GET_LIST_NAME'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const code = `#getListName(${value_player})`;
    return [code, Order.NONE];
}

// 设置玩家列表名
javascriptGenerator.forBlock['PLAYER_SET_LIST_NAME'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setListName(${value_player},${value_name});`;
    return code;
}

// 设置玩家天气
javascriptGenerator.forBlock['PLAYER_SET_WEATHER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setWeather(${value_player},${value_name});`;
    return code;
}

// 重置玩家天气
javascriptGenerator.forBlock['PLAYER_RESET_WEATHER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const code = `#resetWeather(${value_player});`;
    return code;
}

// 设置玩家时间
javascriptGenerator.forBlock['PLAYER_SET_TIME'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const number_time = block.getFieldValue('TIME');

    const code = `#setTime(${value_player},${number_time});`;
    return code;
}

// 设置玩家时间重载方法
javascriptGenerator.forBlock['PLAYER_SET_TIME2'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const number_time = block.getFieldValue('TIME');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setTime(${value_player},${number_time},${value_name});`;
    return code;
}

// 重置玩家时间
javascriptGenerator.forBlock['PLAYER_RESET_TIME'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setTime(${value_name});`;
    return code;
}

// 获取玩家行走速度
javascriptGenerator.forBlock['PLAYER_GET_WALK_SPEED'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getWalkSpeed(${value_name})`;
    return [code, Order.NONE];
}

// 设置玩家行走速度
javascriptGenerator.forBlock['PLAYER_SET_WALK_SPEED'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const number_name = block.getFieldValue('NAME');

    const code = `#setWalkSpeed(${value_name},${number_name});`;
    return code;
}

// 设置玩家是否可以拾取物品
javascriptGenerator.forBlock['PLAYER_SET_CAN_PICKUP_ITEMS'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setCanPickupItems(${value_player},${value_name});`;
    return code;
}

// 关闭玩家背包
javascriptGenerator.forBlock['PLAYER_CLOSE_INVENTORY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#closeInventory(${value_name});`;
    return code;
}

// 玩家生成粒子
javascriptGenerator.forBlock['PLAYER_SPAWN_PARTICLE'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_string = generator.valueToCode(block, 'string', Order.NONE);
    const value_x = generator.valueToCode(block, 'X', Order.NONE);
    const value_y = generator.valueToCode(block, 'Y', Order.NONE);
    const value_z = generator.valueToCode(block, 'Z', Order.NONE);
    const value_num = generator.valueToCode(block, 'num', Order.NONE);

    const code = `#spawnParticle(${value_player},${value_string},${value_x},${value_y},${value_z},${value_num});`;
    return code;
}

// 玩家生成粒子位置
javascriptGenerator.forBlock['PLAYER_SPAWN_PARTICLE_LOCATION'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_location = generator.valueToCode(block, 'location', Order.NONE);
    const value_num = generator.valueToCode(block, 'num', Order.NONE);

    const code = `#spawnParticle(${value_player},${value_name},${value_location},${value_num});`;
    return code;
}

// 设置玩家列表头
javascriptGenerator.forBlock['PLAYER_SET_LIST_HEADER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setListHeader(${value_player},${value_name});`;
    return code;
}

// 设置玩家列表尾
javascriptGenerator.forBlock['PLAYER_SET_LIST_FOOTER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setListFooter(${value_player},${value_name});`;
    return code;
}

// 设置玩家列表头尾
javascriptGenerator.forBlock['PLAYER_SET_LIST_HEADER_FOOTER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_up = generator.valueToCode(block, 'UP', Order.NONE);
    const value_down = generator.valueToCode(block, 'DOWN', Order.NONE);

    const code = `#setListFooter(${value_player},${value_up},${value_down});`;
    return code;
}

// 踢出玩家
javascriptGenerator.forBlock['PLAYER_KICK'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#kick(${value_player},${value_name});`;
    return code;
}

// 强制某个玩家发送一个消息或命令
javascriptGenerator.forBlock['PLAYER_SUDO_CHAT'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#sudoChat(${value_player},${value_name});`;
    return code;
}

// 设置吸收伤害
javascriptGenerator.forBlock['PLAYER_SET_ABSORPTION_AMOUNT'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setAbsorptionAmount(${value_player},${value_name});`;
    return code;
}

// 设置玩家可见性
javascriptGenerator.forBlock['PLAYER_SET_INVISIBLE'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setInvisible(${value_player},${value_name});`;
    return code;
}

// 获取玩家是否可见
javascriptGenerator.forBlock['PLAYER_IS_INVISIBLE'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const code = `#isInvisible(${value_player})`;
    return [code, Order.NONE];
}

// 获取玩家是否在水里
javascriptGenerator.forBlock['PLAYER_IS_IN_WATER'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const code = `#isInWater(${value_player})`;
    return [code, Order.NONE];
}

// 播放声音
javascriptGenerator.forBlock['PLAYER_SEND_SOUND'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_sound = generator.valueToCode(block, 'SOUND', Order.NONE);
    const value_size = generator.valueToCode(block, 'size', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#sendSound(${value_player},${value_sound},${value_size},${value_name});`;
    return code;
}

// 获取玩家游戏模式
javascriptGenerator.forBlock['PLAYER_GET_GAME_MODE'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);

    const code = `#getGameMode(${value_player})`;
    return [code, Order.NONE];
}

// 设置玩家游戏模式
javascriptGenerator.forBlock['PLAYER_SET_GAME_MODE'] = function (block, generator) {
    const value_player = generator.valueToCode(block, 'PLAYER', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setGameMode(${value_player},${value_name});`;
    return code;
}

javascriptGenerator.forBlock['PLAYER_POTION_EFFECT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_potion = generator.valueToCode(block, 'POTION', Order.NONE);
    const value_time = generator.valueToCode(block, 'time', Order.NONE);
    const value_level = generator.valueToCode(block, 'level', Order.NONE);

    const code = `#potionEffect(${value_name},${value_potion},${value_time},${value_level});`;
    return code;
}

javascriptGenerator.forBlock['PLAYER_SET_GLOWING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_boolean = generator.valueToCode(block, 'boolean', Order.NONE);

    const code = `#setGlowing(${value_name},${value_boolean})`;
    return code;
}