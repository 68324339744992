export const world_contents = [
    {
        "kind": "block",
        "type": "PLAYER_GET_WORLD",
        "name": "获取玩家所在的世界"
    }, {
        "kind": "block",
        "type": "WORLD_GET_WORLD",
        "name": "获取世界实例"
    }, {
        "kind": "block",
        "type": "WORLD_SPAWN_EXPERIENCE",
        "name": "生成经验球"
    },
]