export const function_block = [
    {
        "type": "Function_block",
        "tooltip": "这是一个方法，在里面拖入事件方块",
        "helpUrl": "",
        "message0": "方法名 %1 %2 描述 %3 %4 是否启用 %5 %6 %7",
        "args0": [
            {
                "type": "field_input",
                "name": "FUNCTION_NAME",
                "text": "测试方法"
            },
            {
                "type": "input_end_row",
                "name": "1"
            },
            {
                "type": "field_input",
                "name": "FUNCTION_DESCRIPTION",
                "text": "测试方法"
            },
            {
                "type": "input_end_row",
                "name": "2"
            },
            {
                "type": "field_checkbox",
                "name": "ENABLE",
                "checked": "TRUE"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_statement",
                "name": "FUNCTION",
                "check": "flow"
            }
        ],
        "colour": 285,
        "inputsInline": false
    },
    {
        "type": "TRIGGER_BLOCK",
        "tooltip": "当前触发器",
        "helpUrl": "",
        "message0": "当前触发器 %1 请将你的逻辑放入该积木内 %2 帮助页面：https://wiki.ultrafunction.domcer.com/ %3 %4",
        "args0": [
            {
                "type": "input_end_row",
                "name": "1"
            },
            {
                "type": "input_end_row",
                "name": "2"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_statement",
                "name": "4"
            }
        ],
        "colour": 60,
        "inputsInline": false,
    }



]
