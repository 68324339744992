import {function_block} from "@/data/customize/block/function_block";
import {field_block} from "@/data/customize/block/field_block";
import {player_block} from "@/data/customize/block/player_block";
import {logical_control_block} from "@/data/customize/block/logical_control_block";
import {base_block} from "@/data/customize/block/base_block";
import {message_block} from "@/data/customize/block/message_block";
import {location_block} from "@/data/customize/block/location_block";
import {world_block} from "@/data/customize/block/world_block";
import {event_block} from "@/data/customize/block/event_block";
import {server_block} from "@/data/customize/block/server_block";

export const index_block = [
    ...event_block,
    ...function_block,
    ...field_block,
    ...player_block,
    ...message_block,
    ...location_block,
    ...logical_control_block,
    ...base_block,
    ...server_block,
    ...world_block
]