import {base_contents} from "@/data/customize/category/contents/base_contents";

export const base_category = [
    {
        "kind": "category",
        "name": "基本组件",
        "colour": "#A56D5B",
        "contents": [
            ...base_contents
        ]
    }
]