import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['EVENT_SET_CANCELLED'] = function (block, generator) {
    const value_event = generator.valueToCode(block, 'event', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setCancelled(${value_event},${value_name});`;
    return code;
}

javascriptGenerator.forBlock['EVENT_NOW'] = function () {

    const code = 'αEVENT@EVENT';
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['EVENT_GET_LOCALE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getLocale(${value_name})`;
    return [code, Order.NONE];
}